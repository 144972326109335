import React from 'react';

class FileComponent extends React.Component<{ file: any }> {
    render() {
        let {file} = this.props;
        // Extract file extension from the file name (e.g., 469A5038.png)
        // const fileExtension = file.name.split('.').pop().toLowerCase(); // This will give us "png", "jpg", etc.

        // Map file types to images
        const getImagePath = ({fileExtension}: { fileExtension: any }) => {
            switch (fileExtension) {
                case 'png':
                    return require("../../assets/img/files/png.png");
                case 'jpg':
                case 'jpeg':
                    return require("../../assets/img/files/jpg.png");
                case 'doc':
                    return require("../../assets/img/files/doc.png");
                case 'ppt':
                    return require("../../assets/img/files/ppt.png");
                case 'mp4':
                    return require("../../assets/img/files/mp4.png");
                case 'mov':
                    return require("../../assets/img/files/mov.png");
                default:
                    return require("../../assets/img/files/file.png"); // Fallback for unknown types
            }
        };

        return (
            <img
                src={getImagePath({fileExtension: file.name.split('.').pop()})} // Use the file extension to determine the image source
                alt={file.name}
                className="br-7"
            />
        );
    }
}

export default FileComponent;