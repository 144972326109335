// src/components/Login.tsx

import React, {useState} from 'react';
import {Alert, Button, Col, Form, Row} from 'react-bootstrap';
import {Link} from "react-router-dom";
import {API_BASE_URL} from "../../config";

const SignIn = () => {
    const [data, setData] = useState({
        "auth_provider_id": "",
        "auth_provider_token": "",
    })
    const {auth_provider_id, auth_provider_token} = data;
    const changeHandler = (e: { target: { name: any; value: any; }; }) => {
        setData({...data, [e.target.name]: e.target.value})
        setError("");
    }
    const [error, setError] = useState<string | null>(null);

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();

        try {
            const response = await fetch(API_BASE_URL + '/auth/signin', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    auth_provider_id,
                    auth_provider_token,
                }),
            });

            if (!response.ok) {
                console.error(`Login failed with status: ${response.status}`);
                const errorResponse = await response.json();
                console.error('Error details:', errorResponse);

                setError('Invalid login credentials. Please try again.');
                return;
            }

            const body = (await response.json()) as ApiResponse<AuthData>;
            if (!body.success || !body.data || body.errors) {
                console.error('Missing tokens in the response:', data);
                setError('Invalid response from the server. Please try again.');
                return;
            }

            const { access_token, refresh_token } = body.data;
            localStorage.setItem('access_token', access_token);
            localStorage.setItem('refresh_token', refresh_token);

            window.location.href = '/dashboard';
        } catch (error) {
            console.error('An error occurred during login:', error);
            setError('An unexpected error occurred. Please try again later.');
        }
    };

    return (
        <Row
            className="justify-content-center align-items-center authentication authentication-basic h-100">
            <Col
                xl={5}
                lg={6}
                md={8}
                sm={8}
                xs={10}
                className="card-sigin-main mx-auto my-auto py-4 justify-content-center"
            >
                <div className="card-sigin">
                    {/* <!-- Demo content--> */}
                    <div className="main-card-signin">
                        <div className="wd-100p">
                            <div className="">
                                <div className="main-signup-header text-center mb-4">
                                    <h2><strong>EDGE DRIVE</strong></h2>
                                    <h6 className="font-weight-semibold mb-4">
                                        Please sign in to continue.
                                    </h6>
                                </div>
                                <div>
                                    <div className="panel panel-primary">
                                        {error && <Alert variant="danger">{error}</Alert>}
                                        <Form action="#">
                                            <Form.Group className="form-group">
                                                <Form.Label>Email</Form.Label>{" "}
                                                <Form.Control
                                                    className="form-control"
                                                    placeholder="Enter your email"
                                                    onChange={changeHandler}
                                                    name="auth_provider_id"
                                                    value={auth_provider_id}
                                                    type="text"
                                                    required
                                                />
                                            </Form.Group>
                                            <Form.Group className="form-group">
                                                <Form.Label>Password</Form.Label>{" "}
                                                <Form.Control
                                                    className="form-control"
                                                    placeholder="Enter your password"
                                                    type="password"
                                                    onChange={changeHandler}
                                                    name="auth_provider_token"
                                                    value={auth_provider_token}
                                                    required
                                                />
                                            </Form.Group>
                                            <Button onClick={handleSubmit} variant=""
                                                    className="btn btn-primary btn-block">
                                                Sign In
                                            </Button>
                                        </Form>

                                        <div className="panel-body tabs-menu-body border-0 p-3">
                                            <div></div>
                                        </div>
                                    </div>

                                    <div className="main-signin-footer text-center mt-3">
                                        Don't have an account?{" "}
                                        <Link
                                            to={`${process.env.PUBLIC_URL}/signup`}>Create
                                            an Account</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Col>
        </Row>
    );
}

export default SignIn;