// src/App.tsx

import "./index.scss";
import React from 'react';
import {BrowserRouter, Navigate, Route, Routes} from 'react-router-dom';
import SignIn from './components/auth/sign-in';
import Dashboard from "./components/dashboard/dashboard";
import Loader from "./components/loader/loaders";
import EmptyLayout from "./components/layouts/empty";
import AppLayout from "./components/layouts/app";
import SignUp from "./components/auth/sign-up";

const App: React.FC = () => {
    const isAuthenticated = !!localStorage.getItem('access_token'); // Check if user is authenticated

    return (
        <React.Fragment>
            <BrowserRouter>
                <React.Suspense fallback={<Loader/>}>
                    <Routes>
                        {/* Redirect to signin if not authenticated */}
                        <Route path="/" element={isAuthenticated ? <Navigate to="/dashboard"/> : <Navigate to="/signin"/>}/>

                        <Route path="/signin" element={<EmptyLayout><SignIn/></EmptyLayout>}/>
                        <Route path="/signup" element={<EmptyLayout><SignUp/></EmptyLayout>}/>
                        <Route path="/dashboard" element={isAuthenticated ? <AppLayout><Dashboard/></AppLayout> : <Navigate to="/signin"/>}/>
                    </Routes>
                </React.Suspense>
            </BrowserRouter>
        </React.Fragment>
    );
};

export default App;