import React, {useCallback, useEffect, useState} from "react";
import {Button, Card, Col, Dropdown, DropdownItem, Row} from "react-bootstrap";
import {Link} from "react-router-dom";
import Api from '../api/api';
import {API_BASE_URL} from "../../config";
import {useToasts} from 'react-bootstrap-toasts';
import {FilePond} from "react-filepond";

import 'filepond/dist/filepond.min.css';
import File from "./file";

const formatFileSize = (size: number): string => {
    if (size >= 1073741824) { // GB
        return (size / 1073741824).toFixed(2) + " GB";
    } else if (size >= 1048576) { // MB
        return (size / 1048576).toFixed(2) + " MB";
    } else if (size >= 1024) { // KB
        return (size / 1024).toFixed(2) + " KB";
    } else { // Bytes
        return size + " Bytes";
    }
};

const compactFileName = (name: string, maxLength: number = 15): string => {
    if (name.length <= maxLength) return name;

    const extensionIndex = name.lastIndexOf(".");
    const extension = name.slice(extensionIndex);
    const baseName = name.slice(0, extensionIndex);

    const start = baseName.slice(0, 4);
    const end = baseName.slice(-6);
    return `${start}...${end}${extension}`;
};

const api = Api();

const Dashboard = () => {
    const [files, setFiles] = useState<any[]>([]);
    const [pagination, setPagination] = useState({
        page: 1,
        limit: 12,
        total: 0,
        order: "created_at desc"
    });

    const [loading, setLoading] = useState<boolean>(false);

    const [search, setSearch] = useState("");
    const toasts = useToasts();

    const getFiles = useCallback((page: number, limit: number, order: string, search: string) => {
        setLoading(true);
        api.getFiles(page, limit, order, search)
            .then(response => {
                if (response.status !== 200) {
                    throw response;
                }
                setFiles(response.data || []);

                setPagination((prevPagination) => ({
                    ...prevPagination,
                    total: response.meta.total || 0,
                }));
            })
            .catch(error => {
                api.errorHandler(error, toasts)
                setFiles([]);
            })
            .finally(() => setLoading(false));
    }, [toasts]);

    const deleteFile = async (fileId: string) => {
        try {
            await api.deleteFile(fileId);
            await getFiles(pagination.page, pagination.limit, pagination.order, search);
        } catch (err: any) {
            console.error("Error deleting file:", err.message);
        }
    }

    const downloadFile = async (fileId: string, fileName: string) => {
        try {
            await api.downloadFile(fileId, fileName);
        } catch (resp: ApiResponse | any) {
            api.errorHandler(resp, toasts);
        }
    }

    const searchFiles = async (search: string) => {
        setPagination((prevPagination) => ({
            ...prevPagination,
            page: 1,
        }));

        getFiles(pagination.page, pagination.limit, pagination.order, search)
    }

    useEffect(() => {
        getFiles(pagination.page, pagination.limit, pagination.order, search)
    }, [pagination.page, pagination.limit, pagination.order, search, getFiles]);

    const handlePageChange = (newPage: number) => {
        setPagination((prevPagination) => ({
            ...prevPagination,
            page: newPage,
        }));
    };

    const totalPages = Math.ceil(pagination.total / pagination.limit);

    return (
        <div>
            <div className="main-container container-fluid pagination-content">
                <Row>
                    <Col lg={12} xl={12}>
                        <Row>
                            <div className="col-6"></div>
                            <div className="col-6 col-auto">
                                <div className="input-group mb-2">
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Search files....."
                                        value={search}
                                        onChange={(e) => {
                                            setSearch(e.target.value)
                                        }}
                                        onKeyDown={(e) => {
                                            if (e.key === 'Enter') {
                                                searchFiles(search);
                                            }
                                        }}
                                    />
                                    {search && (
                                        <i className="fe fe-x position-absolute"
                                           style={{
                                               top: '50%',
                                               right: '90px',
                                               transform: 'translateY(-50%)',
                                               cursor: 'pointer',
                                               zIndex: '10'
                                           }}
                                           onClick={() => setSearch('')}
                                        ></i>
                                    )}
                                    <Button className="btn ripple btn-primary" type="button" onClick={() => {
                                        searchFiles(search)
                                    }}>
                                        Search
                                    </Button>
                                </div>
                            </div>
                        </Row>
                        <Row>
                            <Col>
                                <FilePond
                                    allowMultiple={false} server={{url: API_BASE_URL + '/files/upload'}}
                                    labelIdle='Drag & Drop your files or <span class="filepond--label-action">Browse</span>'
                                    stylePanelLayout="compact"
                                />
                            </Col>
                        </Row>
                        {loading && (
                            <div className="position-absolute center-align top-50 start-50">
                                <div className={"spinner-grow text-primary align-items-center"} role={"status"}>
                                    <span className="visually-hidden">Loading...</span>
                                </div>
                            </div>
                        )}
                        {!loading && (
                            <Row>
                                {files.map((file, index) => (
                                    <Col sm={6} xl={2} md={4} key={index}>
                                        <Card className="p-0">
                                            <div className="d-flex align-items-center px-3 pt-3">
                                                <Dropdown className="float-end ms-auto optiondots">
                                                    <Dropdown.Toggle
                                                        variant=""
                                                        className="option-dots"
                                                    >
                                                        <i className="fe fe-more-vertical"></i>
                                                    </Dropdown.Toggle>
                                                    <Dropdown.Menu>
                                                        {file.status === 'success' && (
                                                            <>
                                                                <Dropdown.Item href="#"
                                                                               onClick={() => downloadFile(file.hash, file.name)}>
                                                                    <i className="fe fe-download me-2"></i> Download
                                                                </Dropdown.Item>
                                                                <DropdownItem
                                                                    href={"https://whatsonchain.com/tx/" + file.hash}
                                                                    target={"_blank"}>
                                                                    <i className="fe fe-hash me-2"></i> ScriptHash
                                                                </DropdownItem></>
                                                        )}
                                                        <Dropdown.Item href="#" onClick={() => deleteFile(file.id)}>
                                                            <i className="fe fe-trash me-2"></i> Delete
                                                        </Dropdown.Item>
                                                    </Dropdown.Menu>
                                                </Dropdown>
                                            </div>
                                            <Card.Body className="pt-0 text-center">
                                                <div className="file-manger-icon">
                                                    <span className={
                                                        `bg-${file.status} position-absolute translate-top p-2 border border-light rounded-circle`
                                                    }></span>
                                                    <File file={file}/>
                                                </div>
                                                <div className="mt-1">
                                                    <h6 className="mb-1 font-weight-semibold">{compactFileName(file.name)}</h6>
                                                    <span className="text-muted">{formatFileSize(file.size)}</span>
                                                </div>
                                                <div>
                                                    <div className="text-muted mt-3">
                                                    <span
                                                        className={"fe fe-check-circle"}></span> - {file.confirmations}
                                                    </div>
                                                </div>
                                            </Card.Body>
                                        </Card>
                                    </Col>
                                ))}
                            </Row>
                        )}
                    </Col>
                </Row>
            </div>
            {files.length > 0 && (
                <ul className="pagination float-end mb-4">
                    <li className={`page-item ${pagination.page === 1 ? "disabled" : ""}`}>
                        <Link className="page-link" to="#" onClick={() => handlePageChange(pagination.page - 1)}
                              tabIndex={-1}>
                            Prev
                        </Link>
                    </li>

                    {/* Page numbers */}
                    {[...Array(totalPages).keys()].map((page) => (
                        <li key={page} className={`page-item ${pagination.page === page + 1 ? "active" : ""}`}>
                            <Link className="page-link" to="#" onClick={() => handlePageChange(page + 1)}>
                                {page + 1}
                            </Link>
                        </li>
                    ))}

                    <li className={`page-item ${pagination.page === totalPages ? "disabled" : ""}`}>
                        <Link className="page-link" to="#" onClick={() => handlePageChange(pagination.page + 1)}>
                            Next
                        </Link>
                    </li>
                </ul>
            )}
        </div>
    );
};

export default Dashboard;