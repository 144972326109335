import React from "react";

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || "http://localhost:8080";

const Api = () => {
    // Core request method
    const request = async (url: string, options: RequestInit = {}) => {
        const accessToken = localStorage.getItem("access_token");
        const headers: HeadersInit = {
            "Content-Type": "application/json",
            ...(accessToken ? {"X-Auth": accessToken} : {}),
            ...options.headers,
        };

        try {
            const response = await fetch(`${API_BASE_URL}${url}`, {...options, headers});

            if (response.status === 401) {
                localStorage.removeItem("access_token");
                window.location.href = "/signin";
                throw new Error("Unauthorized. Redirecting to login.");
            }

            if (!response.ok) {
                throw await response.json();
            }

            return response;
        } catch (err: any) {
            console.error("Error in request:", err);
            throw err;
        }
    };

    const errorHandler = (resp: ApiResponse | any, toasts: any) => {
        if (resp && resp.status && Array.isArray(resp.errors)) {
            resp.errors.forEach((error: string) => {
                toasts.danger({
                    headerContent: <strong className={"me-auto"}>Error</strong>,
                    bodyContent: error,
                    toastProps: {
                        className: "colored-toast bg-danger text-white",
                    },
                    toastHeaderProps: {
                        className: "bg-danger text-white",
                    },
                });
            });
        } else {
            toasts.danger({
                headerContent: <strong className={"me-auto"}>Error</strong>,
                bodyContent: resp.message || "An unexpected error occurred",
                toastProps: {
                    className: "colored-toast bg-danger text-white",
                },
                toastHeaderProps: {
                    className: "bg-danger text-white",
                },
            });
        }
    };

    const getFiles = async (page: number, limit: number, order: string, search: string) => {
        const params = new URLSearchParams({
            page: page.toString(),
            limit: limit.toString(),
            order,
            ...(search ? {search} : {}),
        }).toString();

        const response = await request(`/files?${params}`);

        return response.json() as Promise<ApiResponse<File[]>>;
    };

    const deleteFile = async (fileId: string) => {
        return request(`/files/${fileId}`, {method: "DELETE"});
    };

    const downloadFile = async (fileId: string, fileName: string) => {
        const response = await request(`/files/${fileId}/download`, {method: "GET"});

        const blob = await response.blob();
        const link = document.createElement("a");

        link.href = URL.createObjectURL(blob);
        link.download = fileName;

        document.body.appendChild(link);

        link.click();

        document.body.removeChild(link);
    };

    return {
        request,
        errorHandler,
        getFiles,
        deleteFile,
        downloadFile,
    };
};

export default Api;