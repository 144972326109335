const EmptyLayout: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    return (
        <div className="page">
            <div className="page-single">
                <div className="container">
                    {children}
                </div>
            </div>
        </div>
    );
};

export default EmptyLayout;