import Footer from "../footer/footer";
import {ToastsProvider} from "react-bootstrap-toasts";

const AppLayout: React.FC<{ children: React.ReactNode }> = ({children}) => {
    return (
        <div className="page">
            <ToastsProvider toastContainerProps={{position:"top-center", className:"p-3"}}>
                <div className="main-content app-content">
                    {children}
                </div>
            </ToastsProvider>
            <Footer/>
        </div>
    );
};

export default AppLayout;